<template>
    <router-view />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('base', {
            selectedProduct: 'selectedProduct',
        }),
    },
    methods: {
        ...mapActions('cadastros', {
            updateBaseData: 'updateBaseData',
            fetchFileTypes: 'fetchFileTypes',
            getStates: 'getStates',
            listUsers: 'listUsers',
            getSegments: 'getSegments',
        }),
    },
    async created() {
        this.fetchFileTypes({ idProduct: this.selectedProduct.id });
        this.getStates();
        this.getSegments({ idProduct: this.selectedProduct.id });
        this.listUsers({ id: 13 });
        this.listUsers({ id: 14 });
        this.updateBaseData({
            selectedProduct: { ...this.selectedProduct },
        });
    },
};
</script>
